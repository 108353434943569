import { Dictionary, Static, String } from "runtypes";

/**
 * Helper functions to ensure that cookies are coming from the correct domains.
 *
 * This helps avoid issues with cookies bleeding from higher to lower order subdomains.
 * Eg. Cookies from us.getequiem.com being sent to hub.us.getequiem.com.
 */

const CookieValue = Dictionary(String, String);
type CookieValue = Static<typeof CookieValue>;

export const unwrapDomainScopedCookieValue = (value: string | undefined, domain: string) => {
  try {
    const parsedCookie = CookieValue.check(JSON.parse(value ?? ""));

    return parsedCookie[domain] ?? "";
  } catch (e: unknown) {
    return "";
  }
};

export const wrapDomainScopedCookieValue = (value: string, domain: string) => {
  const wrappedValue: CookieValue = { [domain]: value };

  return JSON.stringify(wrappedValue);
};
