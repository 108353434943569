import { ApolloClient } from "@apollo/client";
import { transformSite } from "@equiem/web-ng-lib";
import { Maybe, SiteSettingsDocument, SiteSettingsQuery, SiteSettingsQueryVariables } from "../generated/gateway-client";

export class SiteLoader {
  constructor(private client: ApolloClient<any>) { }

  public async load(host: string) {
    const result = await this.client.query<Maybe<SiteSettingsQuery>, SiteSettingsQueryVariables>({
      query: SiteSettingsDocument,
      variables: {
        url: `https://${host}`,
      },
      fetchPolicy: "network-only",
    });

    console.log(`Loading host: ${host}`);

    if (result.data?.destinationByUrl != null) {
      const site = await transformSite(result.data.destinationByUrl);

      return site;
    }

    console.error(`Error loading host: ${host}`, result.errors);

    throw new Error("Site not found");
  }
}
