import { SiteContext } from "@equiem/web-ng-lib";
import { uniq } from "lodash";
import { Cache } from "./Cache";
import { SiteLoader } from "./SiteLoader";

export class SiteRepository {
  private _cache?: Cache;

  constructor(
    private loader: SiteLoader,
    private cacheFactory: () => Cache,
    private ttl: number,
  ) {
  }

  public async findByHost(host: string): Promise<SiteContext> {
    return (
      // Lookup the site in the cache.
      await this.get(host)
    ) ?? (
      // If the site is not already cached, fetch it and cache it.
      await this.set(host, await this.loader.load(host))
    );
  }

  public async purgeByHost(host: string): Promise<void> {
    await this.cache.del(this.hostCid(host));
  }

  public async purgeByUuid(siteUuid: string): Promise<void> {
    // Lookup the existing site.
    const siteKey = this.siteCid(siteUuid);
    const site = await this.cache.get(siteKey, SiteContext);

    if (site != null) {
      // The site is cached, so clear it, and it's hosts.
      await Promise.all([
        // Delete all host->site aliases.
        ...site.domains.map((domain) => this.cache.del(this.hostCid(domain.host))),
        // Delete the site data itself.
        this.cache.del(siteKey),
      ]);
    }
  }

  public async set(host: string, site: SiteContext): Promise<SiteContext> {
    // Purge the site in case it is already cached.
    await this.purgeByUuid(site.uuid);

    // Collate all the hosts for this site.
    const hosts = uniq(site.domains.map((d) => d.host).concat(host));

    // Collate a set of key(host) => value(siteKey) pairs.
    const hostSiteKeys = hosts.reduce<{ [key: string]: { type: "raw"; value: string } }>(
      (values, h) => ({
        ...values,
        [this.hostCid(h)]: { type: "raw" as const, value: this.siteCid(site.uuid) },
      }),
      {},
    );

    const keyValues = {
      // single (siteKey = site settings) pair
      [this.siteCid(site.uuid)]: { type: "object" as const, value: site },
      // multiple (host = siteKey) pairs
      ...hostSiteKeys,
    };

    // Set all key/value pairs at once atomically.
    await this.cache.mset(keyValues, this.ttl);

    return site;
  }

  private async get(host: string) {
    /**
     * Use a script to do a 2-step lookup in a single request:
     *   1. Get the siteKey for the given host
     *   2. Get the site settings for that siteKey if found.
     */
    return this.cache.evalGet(
      "local siteKey = redis.call('get', KEYS[1]); return redis.call('get', siteKey ~= nil and siteKey or '')",
      SiteContext,
      [this.hostCid(host)],
    );
  }

  private hostCid(host: string) {
    return `host:${host}`;
  }

  private siteCid(siteUuid: string) {
    return `site:${siteUuid}`
  };

  private get cache(): Cache {
    return this._cache = this._cache ?? this.cacheFactory();
  }
}
