import { SiteContext } from "@equiem/web-ng-lib";
import { parse } from "cookie";
import { NextPage } from "next";
import { unwrapDomainScopedCookieValue } from "../lib/auth/cookies/domainScopedCookieValueHelpers";
import { formatError } from "../lib/formatError";
import { getSiteRepository } from "../lib/getSiteRepository";
import { globalGateway } from "../lib/globalGateway";
import { AppContextInput, PageInput } from "../lib/PageInput";

export interface SiteProps {
  // Will be set server-side, and undefined client-side.
  siteInput?: SiteContext;
  errors?: string[];
  authenticated: boolean;
  isInMobileView: boolean;
  args: PageInput;
}

/**
 * Unwrap cookie values from an object with the domain as the key. eg. { [domain]: value }
 */
const domainCookieUnwrapper = (cookies: string, domain: string) => (name: string) => {
  const wrappedValue = parse(cookies)[name];

  return unwrapDomainScopedCookieValue(wrappedValue, domain);
};

export const withAppContexts = <P,>(
  Page: NextPage<P>,
  { siteRepo = getSiteRepository(globalGateway), ...args }: AppContextInput,
): NextPage<P & { siteProps: SiteProps }> => {
  // tslint:disable-next-line: no-unbound-method
  const originalGetInitialProps = Page.getInitialProps;

  Page.getInitialProps = async (ctx): Promise<P & { siteProps: SiteProps }> => {
    const props =
      originalGetInitialProps == null
        ? // tslint:disable-next-line: no-object-literal-type-assertion
          ({} as P)
        : await originalGetInitialProps(ctx);
    const loginRedirect = `/api/login?redirect=${encodeURIComponent(
      ctx.asPath ?? "/",
    )}`;

    if (ctx.req?.headers.host == null) {
      // Client-side.
      const unwrapCookie = domainCookieUnwrapper(document.cookie, window.location.hostname);
      const isInMobileView = unwrapCookie("mobile") === "1";

      // If the page is currently viewed in mobile, let it through as the tokens will be grabbed from post message.
      const authenticated = unwrapCookie("auth") === "1" || isInMobileView;

      if (!authenticated && args.requireAuth !== false && !isInMobileView) {
        window.location.href = loginRedirect;
        await new Promise((_) => {
          // Wait for browser to redirect.
        });
      }

      return { ...props, siteProps: { authenticated, isInMobileView, args } };
    } else {
      // Server side.
      try {
        const host = ctx.req.headers.host!;
        const siteInput = await siteRepo.findByHost(host);

        /// Check whether we need to redirect to primary domain.
        const domainConfig = siteInput.domains.find((d) => d.host === host);

        if (
          domainConfig != null &&
          domainConfig.redirect &&
          !domainConfig.primary
        ) {
          const primaryDomain = siteInput.domains.find((d) => d.primary);
          if (primaryDomain != null) {
            // Redirect to primary domain.
            ctx.res
              ?.writeHead(302, {
                Location: `//${primaryDomain.host}${ctx.asPath}`,
              })
              .end();

            return null!;
          }
        }

        const unwrapCookie = domainCookieUnwrapper(ctx.req.headers.cookie ?? "", host.split(":")[0] ?? "");
        const isInMobileView = unwrapCookie("mobile") === "1";
        const authenticated = unwrapCookie("auth") === "1" || isInMobileView;

        if (!authenticated && args.requireAuth !== false && !isInMobileView) {
          ctx.res?.writeHead(302, { Location: loginRedirect }).end();

          return null!;
        }

        return {
          ...props,
          siteProps: { siteInput, authenticated, isInMobileView, args },
        };
      } catch (e) {
        console.error(e);

        return {
          ...props,
          siteProps: {
            errors: [formatError(e)],
            authenticated: false,
            isInMobileView: false,
            args,
          },
        };
      }
    }
  };

  return Page as NextPage<P & { siteProps: SiteProps }>;
};
