import { Redis } from "ioredis";
import { mapValues } from "lodash";
import { Runtype } from "runtypes";

export class Cache {
  constructor(
    private cache: Redis,
    private namespace: string = "",
  ) {}

  /**
   * @param values The key/value pairs to set.
   * @param expiresIn The number of seconds after which to expire the keys.
   */
  public async mset(
    values: { [key: string]: { type: "object"; value: any } | { type: "raw"; value: string } },
    expiresIn: number,
  ): Promise<"OK"> {
    const result = await this.cache.mset(mapValues(
      values,
      (value) => value.type === "object" ? JSON.stringify(value.value) : value.value,
    ));

    if (result === "OK") {
      await Promise.all(Object.keys(values).map((key) => this.cache.expire(key, expiresIn)));
    }

    return result;
  }

  /**
   * @param key The key to retrieve.
   */
  public async get<Value>(key: string, runtype: Runtype<Value>): Promise<Value | null> {
    return this.ofTypeOrNull(
      await this.cache.get(this.key(key)),
      runtype,
    );
  }

  /**
   * @param script The lua script to evaluate.
   * @param keys The keys to pass as arguments to the script.
   * @param args Any additional args to pass to the script.
   */
  public async evalGet<Value>(script: string, runtype: Runtype<Value>, keys: string[] = [], args: string[] = []): Promise<Value | null> {
    return this.ofTypeOrNull(
      await this.cache.eval(script, keys.length, keys.concat(args)) as string | null,
      runtype,
    );
  }

  /**
   * @param keys The keys to delete.
   */
  public async del(...keys: string[]): Promise<void> {
    await this.cache.del(...keys.map((k) => this.key(k)));
  }

  private key(key: string): string {
    return `${this.namespace}${key}`;
  }

  private ofTypeOrNull<Value>(value: string | null, runtype: Runtype<Value>): Value | null {
    if (value == null) {
      return null;
    }

    const obj = JSON.parse(value);

    return runtype.guard(obj) ? obj : null;
  }
}
